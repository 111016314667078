let accordions = document.querySelectorAll("section.accordion .accordion-top");

if (accordions) {
  accordions.forEach(function (accordion, index) {
    accordion.addEventListener("click", function () {
      let parent = accordion.parentNode;
      let nextSibling = accordion.nextElementSibling;
      if (!parent.classList.contains("open")) {
        parent.classList.add("open");
        accordion.setAttribute("aria-expanded", "true");

        nextSibling.style.maxHeight = "1000px";

        setTimeout(function () {
          let height = nextSibling.offsetHeight;

          let newheight = height + "px";
          nextSibling.style.maxHeight = newheight;
        }, 300);
      } else {
        parent.classList.remove("open");
        accordion.setAttribute("aria-expanded", "false");
        nextSibling.style.maxHeight = "0px";
        
      }
    });
  });
}
