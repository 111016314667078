// hamburger nav

const $menuIcon = $('header .menu-wrapper .menu-button');
const $header = $('header');
const $body = $('body');
const $navMenu = $('header .menu-wrapper .nav-menu');
const $languageSwitcher = $('header .language-switcher');

if ($menuIcon) {
    $menuIcon.find('#nav-icon').click(function () {
        if ($menuIcon.hasClass('menu-open')) {
            $menuIcon.removeClass('menu-open');
            $header.removeClass('animate');
            $body.removeClass('no-scroll-y');
            setTimeout(function() {
                $header.removeClass('show-menu');
            }, 300);
            $('header .language-switcher.mobile').css('display','flex');
        }
        else {
            $menuIcon.addClass('menu-open');
            $header.addClass('show-menu animate');
            $body.addClass('no-scroll-y');
            setTimeout(function() {
                $('header .language-switcher.mobile').css('display','none');
            }, 300);
          
        }
    });
}

$languageSwitcher.click(function() {
    $(this).toggleClass('open');
});

$(window).resize(function () {
    if ($(window).width() >= 900) {
        $('header').removeClass('show-menu');
    }
});