const hero = document.querySelector(".hero");
const button = document.querySelector(".hero button");


if (button) {
    const nextSibling = document.querySelector(".hero").nextElementSibling.id;

  button.onclick = function () {
  const url = window.location.href.split('#')[0];
  window.location.href =  url + "/#" + nextSibling;

  };
}
