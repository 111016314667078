window.addEventListener("load", (event) => {
    const isReduced = window.matchMedia(`(prefers-reduced-motion: reduce)`) === true || window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

const allVideos = document.querySelectorAll("video")

    if (!!isReduced) {
        // DON'T use an amination here!

allVideos


allVideos.forEach((element) => {
    //console.log(element);
    element.removeAttribute('autoplay');
    element.removeAttribute('loop');
});
       
        // document.querySelectorAll('video')[0].removeAttribute('autoplay');
    } 

  });