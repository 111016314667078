import './_body-view-height';
import './header';
import './_accordion';
import './_hero-scroll-down';
import './_intersection-observer';
import './_use-reduced-motion';

//import './_slideshow.js'; 

let skipHeader = document.querySelector('a.visually-hidden.skip-to-main');
if(skipHeader != undefined) {
    skipHeader.addEventListener('focusin', function (e) {
        this.classList.add('focused');  
    });
    skipHeader.addEventListener('focusout', function (e) {
        this.classList.remove('focused');  
    });
}